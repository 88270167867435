<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg w-75"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Registrar Nuevo Usuario
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="nombres"
                rules="required"
              >
                <b-form-group
                  label="Nombre Completo"
                  label-for="nombres"
                >
                  <b-form-input
                    id="nombres"
                    v-model="userData.nombres"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Cristian"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="apellidos"
                rules="required"
              >
                <b-form-group
                  label="Apellidos"
                  label-for="apellidos"
                >
                  <b-form-input
                    id="apellidos"
                    v-model="userData.paterno"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Chura Poma"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Genero"
                rules="required"
              >
                <b-form-group
                  label="Genero"
                  label-for="genero"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="userData.genero"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['Masculino', 'Femenino', 'Otro']"
                    :clearable="false"
                    input-id="genero"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Documento Identidad"
                label-for="documento_identidad"
              >
                <b-form-input
                  id="documento_identidad"
                  v-model="userData.documento_identidad"
                  autofocus
                  trim
                  placeholder="Ej. 9123121123"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <!-- Country -->
              <validation-provider
                #default="validationContext"
                name="Pais"
                rules="required"
              >
                <b-form-group
                  label="Pais"
                  label-for="pais"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-if="paises"
                    v-model="userData.pais"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paises"
                    :clearable="false"
                    :reduce="val => val.value"
                    input-id="pais"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="ciudad"
                rules="required"
              >
                <b-form-group
                  label="Ciudad"
                  label-for="ciudad"
                >
                  <b-form-input
                    id="ciudad"
                    v-model="userData.ciudad"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Ej. La Paz"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Telefono"
                label-for="telefono"
              >
                <b-form-input
                  id="telefono"
                  v-model="userData.telefono"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Empresa"
                label-for="empresa"
              >
                <b-form-input
                  id="empresa"
                  v-model="userData.empresa"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Cargo"
                label-for="cargo"
              >
                <b-form-input
                  id="cargo"
                  v-model="userData.cargo"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <!-- Email -->
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required|email|existencia_email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="searchVerificaEmail"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <!-- Username -->
              <validation-provider
                #default="validationContext"
                name="Username"
                rules="required|alpha-num|logitud:2|existencia"
              >
                <b-form-group
                  label="Username"
                  label-for="username"
                  prepend="@"
                >
                  <b-input-group prepend="@">
                    <b-form-input
                      id="username"
                      v-model="searchVerificaUser"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-form-group>
                <label for="basic-password">Password</label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group>
                    <b-form-input
                      id="basic-password"
                      v-model="userData.password"
                      :state="errors.length > 0 ? false:null"
                      :type="passwordFieldType"
                      placeholder="Contraseña"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <!-- User Role -->
              <validation-provider
                #default="validationContext"
                name="User Role"
                rules="required"
              >
                <b-form-group
                  label="Rol"
                  label-for="user-role"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="userData.role"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roleOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="user-role"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Plan"
                rules="required"
              >
                <b-form-group
                  label="Plan"
                  label-for="plan"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="userData.currentPlan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="planOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="plan"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Registrar Usuario
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
          {{ userData }}
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BRow, BCol, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
// import store from '@/store'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import FIND_USER from '../../../graphql/usuario/VerificaSiExisteUser.gql'
import GET_PAISES from '../../../graphql/cursos/pais/SelectPais.gql'
import CREATE_PERSONA_USER from '../../../graphql/usuario/AddPersonaUser.gql'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      nombres: '',
      paterno: '',
      genero: '',
      documento_identidad: '',
      pais: '',
      role: null,
      email: '',
      currentPlan: null,
      company: '',
      contact: '',
      username: '',
    }
    const toast = useToast()

    const searchVerificaUser = ref('')
    const searchVerificaEmail = ref('')
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const { mutate: sendUser, onDone, onError } = useMutation(CREATE_PERSONA_USER, () => ({
      variables: userData.value,
    }))

    onDone(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Persona y cuenta de Usuario Registrado Correctamente',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      emit('refetch-data')
      emit('update:is-add-new-user-sidebar-active', false)
    })

    const onSubmit = () => {
      userData.value.username = searchVerificaUser
      userData.value.email = searchVerificaEmail
      sendUser()
      /*
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
        */
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    // graphQL
    const busqueda = useQuery(FIND_USER, {
      user_email: searchVerificaUser,
    })
    const existe = useResult(busqueda.result, null, data => data.verificarSiExisteUserEmail)

    const {
      result,
      loading,
    } = useQuery(FIND_USER, {
      user_email: searchVerificaEmail,
    })
    const existeEmail = useResult(result, null, data => data.verificarSiExisteUserEmail)

    const queryPaises = useQuery(GET_PAISES)
    const paises = useResult(queryPaises.result, null, data => data.listPais)

    // validación existencia de usuario
    extend('existencia', value => {
      console.log(value)
      if (!existe.value) {
        return !existe.value
      }

      return 'El {_field_} ya está en uso'
    })

    // validación existencia de usuario
    extend('existencia_email', value => {
      console.log(value)
      if (!existeEmail.value) {
        return !existeEmail.value
      }

      return 'El {_field_} ya está en uso'
    })

    // validacion de nro de caracteres

    extend('logitud', (value, maximo) => {
      if (value.length > maximo) {
        return true
      }

      return 'El {_field_} debe tener al menos 3 caracteres'
    })

    onError(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

    return {
      userData,
      searchVerificaUser,
      searchVerificaEmail,
      paises,

      existe,
      loading,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
